<template>
  <div className="animated">
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>Voucher Import
      </b-card-header>
      <b-card-body>
        <b-form v-on:submit.prevent="update">
          <b-row>
            <b-col>
              <b-form-file v-model="file" ref="file-input" class="mb-2" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @change="checkExtension"></b-form-file>
              <p class="mt-0">Selected file: <b>{{ file ? file.name : '' }}</b></p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button type="submit" variant="success" class="m-1">Update Bulk Voucher</b-button>
              <b-button type="button" @click="file = ''" variant="danger" class="m-1">Reset</b-button>
              <b-button type="button" @click="download" variant="primary" class="m-1">Download File Template</b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-row class="mt-3">
          <b-col>
            <h5>Peringatan !</h5>
            <ul style="font-size: 10pt; font-weight: bold">
              <li>Mohon untuk tidak merubah code voucher yang akan diubah.</li>
              <li>Sebelum melakukan prosess update harap cek koneksi untuk meminimalisir koneksi yang terputus.</li>
              <li>Pengisian format data harus sama persis seperti template yang disediakan.</li>
              <li>Jumlah Maksimal baris yang diupdate adalah 500 baris.</li>
              <li>Proses Update Voucher Akan berhenti jika ada baris yang bermasalah. Namun data sebelum baris yang bermasalah akan berhasil diupdate.</li>
              <li>Beberapa Colom yang dapat diubah.
                <ul>
                  <li>Period Start</li>
                  <li>Period End</li>
                  <li>Start From</li>
                  <li>End To</li>
                  <li>Is Active (Available)</li>
                  <li>Wallet Expire Time</li>
                  <li>Expired Type</li>
                </ul>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  export default {
    name: 'voucher-imports',
    data() {
      return {
        file: '',
        isLoading: false
      }
    },
    methods: {
      checkExtension (sender) {
        var validExts = new Array(".xlsx", ".xls");
        var fileExt = sender.target.value;
        fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
        if (validExts.indexOf(fileExt) < 0) {
          this.$swal.fire(
            'Failed!',
            "Invalid file selected, valid files are of " + validExts.toString() + " types.",
            'error'
          ).then(() => {
            this.file = ''
            return false;
          })
        }
        else return true;
      },
      update() {
        if (this.file === '') {
          return this.$swal.fire(
            'Failed',
            'Harap masukan file untuk diimport.',
            'error'
          )
        }
        const formData = new FormData
        formData.append('file', this.file, this.file.name)
          this.$swal.fire({
            title: 'Melakukan Proses Update',
            text: 'Mohon untuk tidak memuat ulang halaman ini!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Kembali'
          }).then((result) => {
            this.isLoading = true
            if (result.value) {
              this.$http.post(`voucher-import`, formData)
              .then(() => {
                this.isLoading  = false
                this.$swal.fire(
                  'Updated!',
                  'Voucher Berhasil diupdate',
                  'success'
                ).then(() => {
                  this.$router.push("/voucher");
                  this.$toasted.success('Voucher successfully updated!')
                })
              }).catch((error) => {
                if (error.response) {
                  this.isLoading = false
                  if(error.response.data.meta.code == 400){
                    this.$swal.fire(
                      'Failed!',
                      error.response.data.meta.message,
                      'error'
                    )
                  }else if(error.response.data.meta.code == 422){
                    this.$swal.fire(
                      'Failed!',
                      error.response.data.meta.message,
                      'error'
                    )
                  }
                }
              })
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.isLoading = false
              this.$swal.fire(
                'Dibatalkan',
                'Proses Update Berhasil dibatalkan!',
                'error'
              )
            }
          })
      },
      download() {
        this.isLoading = true
        this.$http.get(`voucher-template/`)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
    }
  /* Absolute Center Spinner */
  .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
